.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.item-exit {
  opacity: 1;
  overflow: hidden;
}
.item-exit-active {
  opacity: 0;
  overflow: hidden;
  transition:  opacity 300ms ease-out;
}

.screen-enter {
  opacity: 0;
}
.screen-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.screen-exit {
  opacity: 1;
  overflow: hidden;
}
.screen-exit-active {
  opacity: 0;
  overflow: hidden;
  transition:  opacity 300ms ease-out;
}

.floatingPlanet {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(-50%, 0px);
  }
  50% {
    transform: translate(-50%, 7px);
  }
  to {
    transform: translate(-50%, 0px);
  }
}


.show-clip-path {
  -webkit-animation: show-clip-path 700ms linear both;
  animation: show-clip-path 700ms 1s linear both;
}


@keyframes show-clip-path {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);



  }
}




.floatingPlanet {
  animation-name: floatingPlanet;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingPlanet {
  from {
    transform: translate(-50%, 0px);
  }
  50% {
    transform: translate(-50%, 7px);
  }
  to {
    transform: translate(-50%, 0px);
  }
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translateY(0px) ;
  }
  50% {
    transform: translateY(7px) ;
  }
  to {
    transform: translateY(0px) ;
  }
}



@keyframes twinkle {
  0% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0.6;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}


.shake-bottom {
	-webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
 @-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
