.modal {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    z-index: 999;
}
.background-popup {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(255, 255, 255, 0.3);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.modal-content {
    position: relative;
    width: 61%;
    min-height: 30%;
    max-height: 70%;
    background-color: black;
    color: black;
    border: 1% black solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5% 0.5%;
    box-sizing: border-box;
}
.the-real-modal-content {
    position: relative;
    width: 100%;
    background-color: white;
    height: 100%;
    overflow-y: auto;
}

.one-line-popup-content {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.4em;
  justify-content: center;
  font-family: HelveticaBold;
  padding: 0 10%;
  text-align: center;
}    
.fade-in {
	-webkit-animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.close-btn {
  position: absolute;
  right: 2%;
  top: 2%;
  cursor: pointer;
  font-size: 0.1;
  line-height: 0.7;
  aspect-ratio: 1/1;
  display: block;
  background: transparent;
  border: 0;
  font-size: 0.6em;
  font-weight: 100;
}



.scrollbar-design::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollbar-design::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.scrollbar-design::-webkit-scrollbar-thumb
{
	background-color: #000000;
}



/* ----------------------------------------------
 * Generated by Animista on 2023-1-27 17:43:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  