@font-face {
    font-family: Helvetica;
    src: url("../font/Helvetica.ttf");
  }
  @font-face {
    font-family: HelveticaBold;
    src: url("../font/Helvetica-Bold.ttf");
  }
  @font-face {
    font-family: HelveticaNeue;
    src: url("../font/HelveticaNeue.otf");
  }