.solutions-container{
  position: relative;
  color: black;
  top: 28%;
  left: 13%;
  width: 74%;
  height: 44%;
  display: flex;
  justify-content: flex-end;
  gap: 7%;
  box-sizing: border-box;
  padding-right: 5%;
}
.solution{
   
    height: 100%;
    width: 10%;
    position: relative;
    display: flex;
    align-items: center;
}


.range-bar{
    border: 1px solid white;
    position: absolute;
    bottom: 2%;
    width: 90%;
    height: 20%;
   
}
.scale{
   width: 100%;
   /* display: flex;
   align-items: center;
   justify-content: space-between; */


}
.scale div{
    width: 10%;
    
 }
 
.validate-calibration{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
    /* background: #CC0099 ;
    color: white; */
    position: relative;
    width: 69%;
    height: 5%;
    border-radius: 5px;
    bottom: -43%;
    left: 50%;
    transform: translateX(-50%);
   
    
 }
.correct-answers-container{
    background-color: white;
    color: #CC0099 ;
    border-radius: 5px;
  
 }
.answers-container,.correct-answers-container{
    width: 40%;
    height: 70%;
    padding: 1%;
  
 }


.validate-btn{
border: 1px solid black;
width: 10%;
height: 5%;
padding: 1%; 
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
cursor: pointer;
position: absolute;
bottom: 2%;
right: 50%;
transform: translateX(50%);

}
.finalSolution {
  position: absolute;
  height: 23%;
  width: 9%;
  top: 49%;
  left: 30.3%;

}

.indicator {
  width: 15%;
  position: absolute;
  top: 40%;
  left: 27.5%;

}
.finalSolution > div {
  position: relative;
  height: 100%;
  width: 100%;
}
.finalSolution > div > svg{
  height: 100%;
  width: 100%;
  display: block;
}

.attempts{
    position: absolute;
    color: white;
    background: red;
    width: 40%;
    height: 8%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    padding: 1%;


} 
.solution-status{
    width: 5%;
    height: 3.5%;
    
    position: absolute;
    right: 1%;
    top: 1%;
   
}
.solution-status.true{
    background-color: green;
   
}

.solution-status.false{
   background: red;
   
}
.showModal-btn{
    border-radius: 50%;
    position: absolute;
    width: 2%;
    height: 3.4%;
    border: 1px solid rgb(77, 52, 126);
    /* background: #CC0099;
    color: white; */
    cursor: pointer;
    /* display: flex; 
     align-items: center; 
    justify-content: center; */
    background-color: rgb(77, 52, 126);
    margin-top: 2%;
}




.tube{
  width: 94%;
}

  .myBar{
    display: flex;
      /* width: 898px; */
      justify-content: space-between;
      align-content: center;
      align-items: center;
  }
  .progress {
    width: 840px;
    height: 15px;
    /* UI Properties */
    background: var(--unnamed-color-373737) 0% 0% no-repeat padding-box;
    background: #373737 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    transform: rotateZ(-90deg)
  }
  .progressBar{
    /* height:118px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
pre{
    white-space: break-spaces;
}
.edit_dosage_btn{
  width: 100% !important;
  height: 10% !important;


}

.checkMark {
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  transition: clip-path 0.5s cubic-bezier(0.4, 0, 1, 1) ;
}

.checkMark.visible {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

}

.heartContainer {
  position: absolute; 
  height: 10%;
  width: 15%;
  top: 3%;
  right: 3%;
  text-align: right;

}
.heartContainer > div {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 2.5em;
  line-height: 1;
}

.heartContainer > div > svg {
  position: relative;
  width: 20%;
  padding: 1%;
  box-sizing: border-box;
}




ol > li::marker {
  font-weight: bold;
}

.content {

}


.content.ref {
  
}

.content .title {
  text-align: center;
  font-size: 0.5em;
  margin: 0.6em auto;
}

.content section {
  font-size: 0.3em;
  padding: 0 2em;
  margin-bottom: 1.8em;
}

.standalone.content {
    height: 90%;
    width: 80%;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content.standalone section {
  font-size: 0.4em;
  margin: 0;

}
.content.standalone .title {
  font-size: 0.6em;
  margin-bottom: 0;
}


.content section > p {
  margin-bottom: 1.7em;
  font-family: HelveticaBold;
  line-height: 1.3em;
  
}

.content.standalone section > p {
  font-family: Helvetica;
  
}
.center-text {
  text-align: center;
}

.dosage {
  position: absolute;
  text-align: center;
  width: 12%;
  top: 31%;
  left: 29%;
}
.dosage > span {
  font-size: 3.1em;
  line-height: 1;
  
}
.indicator-circle{
  fill:#fff;
  transition : fill 0.4s linear;
}

.indicator-circle.red.active {
  fill:#ff4800;
}
.indicator-circle.orange.active {
  fill:#ff7200;
}
.indicator-circle.yellow.active {
  fill:#ffe340;
}
.indicator-circle.green.active {
  fill:#1ad698;
}

