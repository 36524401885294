$widthGameContainer: 40% ;
$leftGameContainer: 30%;
$topGameContainer : 30%;
$indicatorHeight : 7%;
$bottleLeftFromGameContainer: 7%;
$collistionLeft: 10%;
.guitarHeroContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    & > div{
        height: 100%;
        width: 100%;
        position: relative;
    }
}
.skipTutorial {

  font-weight: 700;
  border: 3px solid black;
  box-sizing: border-box;

}

.center-consigne {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 0.1em;
  padding-right: 5%;
}

.guitarhero-Text-container {
  height: 22%;
  width: 58%;
  top: 4%;
  left: 50%;
  position: absolute;
  background: #68d2df;
  background: linear-gradient(90deg, #68d2df 0%, #9337ff 100%);
  padding: 0.15em;
  box-sizing: border-box;
  transform: translateX(-50%);
  &.forCallibration {
    // top: 2%;
    // left: 15%;
  }
  & > div {
    height: 100%;
    width: 100%;
    position: relative;
    font-family: HelveticaBold ;
    background: white;
    display: flex;
    & h1 {
      letter-spacing: 0.1em;
      color: #68d2d1 ;
      font-size: 1em;
      font-family: HelveticaNeue ;
    }
    & p {
      font-weight: 100;
      font-size: 0.37em;
    }
    & h2 {
      margin-top: 1%;
      color: #68d2d1;
      font-size: 0.6em;
      font-weight: lighter;
    }
  }
}
.backgroundGuitarHero {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  & > div{
    height: 100%;
    width: 100%;
    position: relative;
    & > img.background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    & > img.bulbIllustration {
      position: absolute;
      height: 18%;
      left: 3%;
      top: 5%;
      object-fit: contain;
      &.forCallibration {
        left: 1%;
        top: 2%;
      }
    }
    & > img.redIllustration {
      position: absolute;
      height: 8%;
      left: 10%;
      top: 12%;
      bottom : 10px;
      object-fit: contain;
      animation: float 10s 3s ease-in-out infinite;

    }
    & > img.blueIllustration {
      position: absolute;
      height: 10%;
      left: 13%;
      top: 26%;
      object-fit: contain;
      animation: float 12s 1s ease-in-out infinite;
  }
    
    & > img.orangeIllustration {
      position: absolute;
      height: 8%;
      left: 3%;
      top: 30.4%;
      object-fit: contain;
      animation: float 6s 3s ease-in-out infinite;
    }
    & > .combo {
      position: absolute;
      left: 71%;
      top: 30.8%;
      width: 5%;
      height: 6%;
      object-fit: contain;
      font-family: 'HelveticaNeue';
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & > .x {
        font-size: 0.4em;
      }
      & > .number {
        font-size: 0.8em;
      }
    }
    
  }
}

.bottles-container {
  position: absolute;
  height:40.7%;
  left: calc(#{$leftGameContainer} + (#{$collistionLeft} / 2) - 0.6% );
  top: calc(#{$topGameContainer} + #{$indicatorHeight});
  & > div {
    position: relative;
    height: 100%;
    width: 100%;
  }
}
.indicators {
  background: white;
  height: $indicatorHeight;
  width: $widthGameContainer;
  border: 2px solid;
  border-radius: 7px;
  position: absolute;
  left: $leftGameContainer;
  top: $topGameContainer;
  overflow: hidden;
  box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 5%;
    top: 0;
    background-color: black;
    transform: translateX(-25%);
    z-index: 5;
  }
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 5%;
    background-color: black;
    top: 0;
    right: 0;
    transform: translateX(25%);
    z-index: 5;
  }
  & > div {
    height: 100%;
    width: 100%;
    position: relative;
  

    & > .bullet {
        position: absolute;
        height: 50%;
        // width: 1.5em;
        aspect-ratio: 1 / 1;
        background-color: blue;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
    & > .bullet.red { 
      background: rgb(255,135,195);
      background: -moz-linear-gradient(90deg, rgba(255,135,195,1) 0%, rgba(217,27,165,1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(255,135,195,1) 0%, rgba(217,27,165,1) 100%);
      background: linear-gradient(90deg, rgba(255,135,195,1) 0%, rgba(217,27,165,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff87c3",endColorstr="#d91ba5",GradientType=1);
    }
    & > .bullet.blue { 
      background: rgb(104,210,223);
      background: -moz-linear-gradient(90deg, rgba(104,210,223,1) 0%, rgba(143,46,255,1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(104,210,223,1) 0%, rgba(143,46,255,1) 100%);
      background: linear-gradient(90deg, rgba(104,210,223,1) 0%, rgba(143,46,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#68d2df",endColorstr="#8f2eff",GradientType=1);
    }
    & > .bullet.orange { 
      background: rgb(255,227,64);
      background: -moz-linear-gradient(90deg, rgba(255,227,64,1) 0%, rgba(255,103,0,1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(255,227,64,1) 0%, rgba(255,103,0,1) 100%);
      background: linear-gradient(90deg, rgba(255,227,64,1) 0%, rgba(255,103,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffe340",endColorstr="#ff6700",GradientType=1);
    }
    & > .bullet.hover {filter: drop-shadow(0px 0px 4px black)}
    & > .bullet.wrong {filter: drop-shadow(0px 0px 10px red)}
    & > .bullet.right {
        filter: drop-shadow(0px 0px 10px green);
        -webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }
    &::after {
        content: "";
        position: absolute;
        background-color: black;
        box-sizing: border-box;
        height: 2px;
        width: 100%;
        top: 50%;
    }

    & > .collistion {
        height: calc(100% + 2px);
        width: 5%;
        border: 0px solid black;
        box-sizing: border-box;
        position: absolute;
        left: $collistionLeft;
        top: -1px;
        & > .arrow {
          position: absolute;
          height: 100%;
          left: 50%;
          transform: translateX(-47%);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          line-height: 0.8em;

        }
        & > img {
          position: absolute;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          object-fit: contain;
        }
        &::after {
        content: "";
        position: absolute;
        background-color: black;
        box-sizing: border-box;
        height: 100%;
        width: 1px;
        left: 50%;
        }
        
    }
  }
}

.white-svg{fill:#fff}
.blue-svg{fill:#7c7eef}
.red-svg{fill:#d63d9b}
.orange-svg{fill:#f2a93b}


.blue-svg.animation {fill: url("#linear-gradient-animation")}
.red-svg.animation {fill: url("#linear-gradient-2-animation")}
.orange-svg.animation {fill: url("#linear-gradient-3-animation")}


.doctor-size-position {
  position: absolute;
  height: 70%;
  left: 13%;
  bottom: 0%;
}

.doctor-cotainer {
  position: relative;
  height: 100%;
  width: 100%;
}
.doctor-animation {
  height: 100%;
  object-fit: contain;
}

.says-container {
  position: absolute;
  top: -3%;
  left: 0;
  width: 100%;
  transform: translate(-50%,-30%);
  text-align: center;
}
.bubble-says {
  position: relative;
  height: 100%;
  width: 100%;
}

.bubble-says-background {
  width: 100%;
  object-fit: contain;
}

.bubble-says-text {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10%;
  padding-bottom: 24%;
  box-sizing: border-box;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes float {
	0% {
		transform: translatey(0px) ;
	}
  45% {
		transform: translatey(-20px);
	}
	50% {
		transform: translatey(-20px);
	}
  55% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px) ;
	}
}


 .transition {
	-webkit-animation: transition 3s linear both;
	        animation: transition 3s linear both;
}

 @-webkit-keyframes transition {
    0% {
        right: 0%;
    }
    100% {
        right: 100%;
    }
  }
  @keyframes transition {
    0% {
        right: 0%;
    }
    100% {
        right: 100%;
    }
  }
  

  .slide-out-top {
	-webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-6 11:12:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2023-1-12 19:8:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-vertical
 * ----------------------------------------
 */
@-webkit-keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.jello-vertical {
	-webkit-animation: jello-vertical 0.9s both;
	        animation: jello-vertical 0.9s both;
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s 1s ease-in-out both;
	        animation: heartbeat 1.5s 1s ease-in-out both;

  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-4-24 21:37:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: translateX(-50%) scale(1);
            transform: translateX(-50%) scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: translateX(-50%) scale(0.91);
            transform: translateX(-50%) scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: translateX(-50%) scale(0.98);
            transform: translateX(-50%) scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: translateX(-50%) scale(0.87);
            transform: translateX(-50%) scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: translateX(-50%) scale(1);
            transform: translateX(-50%) scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: translateX(-50%) scale(1);
            transform: translateX(-50%) scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: translateX(-50%) scale(0.97);
            transform: translateX(-50%) scale(0.97);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: translateX(-50%) scale(1);
            transform: translateX(-50%) scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: translateX(-50%) scale(0.95);
            transform: translateX(-50%) scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: translateX(-50%) scale(1);
            transform: translateX(-50%) scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
