.background_intro {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo_intro {
  position: absolute;
  width: 18%;
  top: 12%;
  left: 50%;
  transform: translateX(-50%);
}

.MessageList_poopup_block {
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.intro__block {
  position: relative;
  height: 100%;
  padding: 13% 28%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

.intro__block.container {
  height: 83%;
  padding: 0% 30%;
  justify-content: space-evenly;

}

.intro__block.container.wider_container {
  padding: 0% 10%;

}

.intro__block.middle {
  justify-content: center;
}
.intro__block__center {
  text-align: center;
  margin: 0 auto;
  display: block;
}


.font-size-01 {
  font-size: 0.1em;
}
.font-size-02 {
  font-size: 0.22em;
}
.font-size-03 {
  font-size: 0.3em;
}
.font-size-04 {
  font-size: 0.4em;
}
.Intro__blockMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
}

.MessageBox {
  border: black solid 1px;


}

.Message__image {
  width: 49.5%;
  border: black solid 1px;
}

.message__text {
  width: 49.5%;
  border: red solid 1px;
}