@mixin viewportRatio($x, $y) {
  width: 100vw;
  height: $y * 100vw / $x;
  max-width: $x / $y * 100vh;
  max-height: 100vh;
}

.blue{color:#7c7eef} // 0
.pantone{color:#d63d9b} // 1 
.orange{color:#f2a93b} // 2
.crayola{color : #68D2D1} // 3 
.my-class {
  color: #ff023e;
}
.lang-container {
  position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.App {
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: #282c34;
}

.canvasContainer {
  @include viewportRatio(16, 9);
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
  background-color: whitesmoke;
}
#popup-root {
  @include viewportRatio(16, 9);
}
.canvasContainer > .canvas {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
}
h1 {
  font-family: HelveticaNeue;
}
#SceneManager {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > div {
    position: relative;
    height: 100%;
    width: 100%;
  }
  & .Screen {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    & > div {
      position: relative;
      height: 100%;
      width: 100%;
    }
  
    // & > div:before {
    //   content: "";
    //   position: absolute;
    //   height: 100%;
    //   width: 1px;
    //   background-color: black;
    //   left: calc(50% - 0.5px);
    //   z-index: 99999;
    //   display: block;
    // }
    // & > div:after {
    //   content: "";
    //   position: absolute;
    //   height: 1px;
    //   width: 100%;
    //   top: calc(50% - 0.5px);
    //   z-index: 99999;
    //   background-color: black;
    //   display: block;
    // }
  }
}

.screen-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  & > div {
    position: relative;
    height: 100%;
    width: 100%;
  }
}


.img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.widget {
  position: absolute;
  cursor: pointer;
  -webkit-tap-highlight-color:transparent;
  line-height: 1;
  &.disabled {
    filter: opacity(0.4);
    pointer-events: none;

  }
  &.primary {
    padding: 0 4%;
    background: black;
    border-radius: 30px;
  }
  &.primary > .splachscreen_play__block {
    // font-size: 3em;
    // height: auto;
    color: white;
    width: 100%;
   
  }
  & > div {
    position: relative;
    height: 100%;
    width: 100%;
    transform : scale(1);
    transition: transform 0.1s linear;
    &:active {
      transform : scale(0.9);
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: block;
      content: "";
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &:hover::before {
      -webkit-animation: circle 0.4s;
      animation: circle 0.4s;
    }
    & > img {
      position: relative;
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}


@keyframes fade-in-up {
  0%,
  10% {
    top: -60%;
    opacity: 1;
  }
  100% {
    top: -150%;
    opacity: 0;
  }
}

.plus-number {
  position: absolute;
  animation-name: fade-in-up;
  animation-duration: 3s;
  animation-direction: alternate;
  animation-timing-function: ease;
  left: 0;
  top: -60%;
}

.plus-number-score {
  position: absolute;
  animation-name: fade-in-up;
  animation-duration: 3s;
  animation-direction: alternate;
  animation-timing-function: ease;
  left: 45%;
  top: -60%;
  transform: translateX(-55%);
  font-size: 1em;
  &.positive {
    color: #00ff00;
  }
  &.negative {
    color: #ff023e;
  }
}
.imageManager {
  background: rgb(7, 7, 7);
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  & > .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .bg {
    width: calc(300% / 16);
    height: calc(300% / 9);
    background: #824b20;
    position: absolute;
    // border-radius: 50%;
    overflow: hidden;
    display: flex;
    animation: anim 2s;
    animation-iteration-count: infinite;
    & > .c {
      position: relative;
      width: 50%;
      height: 50%;
      background: #e08027;
      // border-radius: 50%;
      display: grid;
      justify-content: center;
      align-content: center;
      font-size: 2em;
    }
    & > .cload {
      width: 100%;
      height: 0%;
      background: rgba(255, 0, 0, 0.123);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transition: height 0.3s linear;
    }
  }
}

@keyframes anim {
  0%,
  100% {
    transform: rotate(0deg) sacle(1);
  }
  25% {
    transform: rotate(6deg) scale(1.1);
  }
  50% {
    transform: rotate(-6deg) scale(1.1);
  }
  75% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes pulse {
  25% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
}
.italic {
  font-style: italic;
}
.bold {
  font-weight: bolder;
}
.yellow {
  color: #f8ad3b;
}

.codeReg {
  position: absolute;
  color: black;
  text-align: center;
  font-size: 0.2em;
  transform: rotate(270deg);
  right: 0%;
  top: 48%;
  font-weight: bold;
}


.fade-in {
	-webkit-animation: fade-in 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-4-28 19:8:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


video[poster] {
  object-fit: cover;
}