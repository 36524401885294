.background_splashscreen {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo_splashscreen {
  position: absolute;
  width: 38%;
  top: 26%;
  left: 50%;
  transform: translateX(-50%);
}

.dr_splashscreen {
  position: absolute;
  bottom: 20%;
  left: 3%;
  height: 60%;
}

.dr_von_behring_splashscreen {
  position: absolute;
  bottom: 0;
  right: 13%;
  height: 60%;
}

.biocodex_logo_splashscreen {
  position: absolute;
  right: 5%;
  top: 5%;
  height: 10%;
}
.planet_splashscreen {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 35%;
  height: 20%;
}

.play_btn_splashscreen {
  left: 50%;
  transform: translateX(-50%);
}

.sound_btn_splashscreen {
  left: 50%;
  transform: translateX(-50%);
}
.splachscreen_play__block {
  display: flex;
  gap: 1em;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
